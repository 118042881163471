.navbar-brand img{
  width: 300px;
}
body{
  background-color: #E5E6F2 !important;
}
.App-header {
  transition: all 0.5s;
  box-shadow: 0px 2px 15px rgb(0 0 0 / 22%);
}
.theme-color{
  color: #0b1a46 !important;
}


#home{
  scroll-margin-top: 0;
}
#about,
#mission,
#affiliate,
#contact{
  scroll-margin-top: 100px;
}


.theme-color.nav-link{
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 400;
}
body .bg-body-tertiary{
  --bs-bg-opacity: 1;
  background-color: #E5E6F2 !important;
  padding: 20px 0;
}
.bg-scroll .theme-color.nav-link.item--active {
  color: #fff !important;
}
.offcanvas.offcanvas-end.show .theme-color.nav-link.item--active{
  color: #49b5e7 !important;
}
.theme-color.nav-link:hover {
  color: #fff !important;
  background: #3d66e1 !important;
}
.theme-color.nav-link{
  text-transform: capitalize !important;
}
.hero{
  /* margin-top: 105px; */
  padding: 105px 0 50px;
}
.hero .container{
  background: url("../public/assets/img/Background06.png") no-repeat;
  background-position: bottom center;
  background-size: cover;
  padding: 100px 50px 0;
}
.button-navbar a{
  display: flex;
  align-items: center;
  width: 150px;
  justify-content: space-evenly;
  border-radius: 15px;
  padding: 10px 30px;
  background: #3d66e1 !important;
  color: #fff !important;
}
.navbar .nav-link{
  padding: 13px 20px !important;
}
.item--active.nav-link{
  background: #3d66e1 !important;
  color: #fff !important;
}
.heroSection h1{
  font-size: 50px;
  font-weight: 900;
}
.typetext p{
  font-family: 'Poppins', sans-serif;
}
.btn-cs{
  font-family: 'Poppins', sans-serif !important;
  background-color: #0B1A47 !important;
  color: #fff !important;
  border: 0 !important;
  padding: 20px 50px !important;
  font-size: 17px !important;
  font-weight: 700 !important;
  border-radius: 20px !important;
  transition: all .3s ease-in-out;
}
.btn-cs:hover{
  background-color: #87a4ff !important;
  transform: scale(0.9);
  transition: all .3s ease-in-out;
}
.hero-left-col{
  padding-bottom: 250px;
}
.affiliate h2{
  margin-bottom: 50px;
  font-weight: bold;
  font-size: 40px;
}
.affiliate{
  padding: 100px 0 0;
}
#about{
  padding: 100px 0 0 ;
}

.cs-list-item svg{
  font-size: 50px;
  color: #3d66e1 !important;
  display: block;
  position: relative;
}

.cs-list-item h3{
  font-size: 20px;
  font-weight: 700;
}
.cs-list-item p{
  font-size: 16px;
  font-weight: 400;
}
.cs-list-item {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 10px;
}
.cs-list-content {
  margin: 15px 0 0 15px;
  width: 100%;
} 
.bg-gray-1{
  background-color: transparent;
  background-image: linear-gradient(180deg, #e5e6f2 0%, #F7F9FD 100%);
}
.about-dmm-2 .text-img{
  margin: 0 70px 0 0;
}
.text-img{
  border-radius: 20px;
  overflow: hidden;
  margin-left: 70px;
}
.text-img img{
  width: 100%;
}
.bg-gray{
  padding: 100px 0;
  background-color: #F7F9FD;
}
.about h2{
  margin-bottom: 20px;
  font-weight: bold;
  font-size: 40px;
  color: #0b1a46;
}
.about-dmm p{
  padding-right: 30%;
}

.cta h2{
  font-size: 38px;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
  margin-bottom: 20px;
}
.cta p{
  margin-bottom: 20px;
}
.cta{
  padding: 50px 0;
}
.cta .btn-cs {
  width: 280px;
  margin: 0 10px;
  background: #87a4ff !important;
}
.cta-content{
  margin: 20px 0;
  padding: 75px;
  background-color: transparent;
  background-image: linear-gradient(180deg, #0b1a46 0%, #3d66e1 100%);
  border-radius: 15px;
}
.cta .btn-cs.call {
  background: #3d66e1 !important;
}

.mission{
  background-color: #fff;
  padding: 100px 0;
}
.mission h2{
  color: #0b1a46 !important;
  font-size: 28px;
  font-weight: bold ;
}
.mission h3.same-h{
  display: inline-flex;
  align-items: flex-end;
  color: #0b1a46 !important;
  font-size: 19px;
  font-weight: 600 ;
  min-height: 68px;
}
.mission-img {
  border-radius: 15px;
  overflow: hidden;
}
.m-gradient {
  background-color: transparent;
  background-image: linear-gradient(180deg, #87a4ff 0%, #3d66e1 100%);
  padding: 28px;
  border-radius: 15px;
}
.m-gradient h3{
  font-size: 19px;
  font-weight: 600 ;
}
.m-row-2{
  margin-top: 50px !important;
}

.post{
  padding: 100px 0;
}
.post .card-img-top{
  border-radius: 0 !important;
}
.post .card{
  border-radius: 15px;
  overflow: hidden;
}

.post .h5{
  color: #0b1a46;
  font-size: 28px;
  font-weight: bold;
  font-family: 'Poppins', sans-serif;
  line-height: 44px;
}

.post .card-body{
  padding: 30px;
}

.contact{
  padding: 100px 0;
  background: url('../public/assets/img/pexels-photo-209663.jpg');
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
}
.contact h2{
  margin-bottom: 50px;
  color: #fff;
  font-size: 45px;
  font-weight: bold;
  font-family: 'Poppins', sans-serif;
}
.contact .form-static{
  max-width: 700px;
  margin: auto;
  background: #fff;
  padding: 40px;
  border-radius: 10px;
}

.contact form input,
.contact form textarea{
  margin: 10px;
  padding: 10px 20px;
  font-family: 'Poppins', sans-serif;
}
.common-question{
  color:  #fff;
  text-align: center;
  position: relative;
}
.common-question:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 37%;
  width: 2%;
  height: 20%;
  background: #ff7f50;
  transform: translate(-50%, -50%);
}
.common-question:before {
  content: "";
  position: absolute;
  top: 50%;
  right: 35%;
  width: 2%;
  height: 20%;
  background: #ff7f50;
  transform: translate(-50%);
}


.contact form .cs-btn-form{
  width: 100%;
  margin: 10px;
  border-radius: 15px;
  padding: 15px 30px;
  background: #0b1a46;
  border: 0;
  font-weight: bold;
  text-transform: uppercase;
}
.contact form .cs-btn-form:hover{
  background: #3d66e1;
}
.contact-btn{
  max-width: 700px;
  margin: 50px auto 0 !important;
}
.contact-btn p,
.contact-btn a{
  color: #fff;
  text-decoration: none;
}

.contact-btn .icon{
  background: #3d66e1;
  width: 60px;
  height: 60px;
  margin: auto;
  border-radius: 60px;
  line-height: 55px;
  margin-bottom: 20px;
}
.contact-btn .icon svg{
  font-size: 30px;
}


footer{
  background: #0b1a46;
  overflow: hidden;
}
.copyright p{
  margin-bottom: 0;
}
.copyright .container{
  padding: 2% 0;
  border-top: 1px solid #a0aaba2b;
}
.footer-link{
  padding: 50px 0;
}
.footer-link .list-group{
    border-radius: 0;
    padding: 0;
}
.footer-link .list-group .list-group-item{
  background: transparent;
  border: 0;
  padding: 0;
}

.sm-icons{
  padding: 15px;
  background: #3d66e1;
  color: #fff;
  margin: 0 10px;
  border-radius: 50px;
}
.sm-icons:first-child{
  margin-left: 0 !important;
}
.sm-icons:last-child{
  margin-right: 0 !important;
}

.sm-icons svg{
  font-size: 20px;
}

footer h3{
  margin-bottom: 20px;
}
.footer-desc{
  margin-bottom: 50px;
}

footer h6{
  color: #fff;
}
.link{
  padding: 0;
}

.link li a{
  color: #fff;
  text-decoration: none;
}
.link li{
  display: block;
  padding: 10px 0;
}

.link li a svg,
.ci-link svg{
  color: #3d66e1;
}
.ci-link{
  padding: 0;
}
.ci-link li{
  display: flex;
  margin: 20px 0;
}
.ci-link p,
.ci-link a{
  color: #fff;
  text-decoration: none;
}
.pl-3{
  padding-right: 50px !important;
}
.ci-link svg{
  margin-right: 10px;
}
#offcanvasNavbarLabel-expand-xl img{
  width: 150px;
}
@media only screen and (min-width: 768px) {

  .hero .container,
  header .container{
    max-width: 1440px !important;
  }
}


@media only screen and (max-width: 1199px) {
  .offcanvas .pe-3 {
    padding: 0 !important;
    align-items: flex-start !important;
  } 
  .offcanvas .button-navbar.navbar-nav{
    display: none;
    position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 10px;
  }
  .offcanvas .button-navbar.navbar-nav a{
    width: 60%;
    margin: auto;
  }
  body .offcanvas.offcanvas-end.show .theme-color.nav-link.item--active,
  body .offcanvas.offcanvas-end.show .theme-color.nav-link.item--active:hover {
      color: #3d66e1  !important;
      background: transparent !important;
  }
  .theme-color.nav-link:hover {
    color: #3d66e1  !important;
    background: transparent !important;
}
  
}

@media only screen and (max-width: 1023px) {
  .hero .container {
    padding: 40px 20px 0;
  }
  .hero-left-col {
    padding-bottom: 150px;
  }
}

@media only screen and (max-width: 991px) {
  .heroSection .row{
    flex-direction: column-reverse;
  }
  .solo-img{
    text-align: center;
  }
  .solo-img img{
    max-width: 500px;
  }
  .affiliate {
    padding: 50px 0 0;
  }
  .affiliate h2 {
    font-size: 30px;
  }
  #about {
    padding: 50px 0 0;
}
  .about h2 {
    font-size: 30px;
  }
  .text-img {
    margin-left: 0;
}
.about-dmm p {
  padding-right: 0;
}
.bg-gray {
  padding: 50px 0;
}
.about-dmm-2 .text-img {
  margin: 0;
}
.cta-content {
  padding: 30px;
}
.cta h2 {
  font-size: 30px;
}
.mission {
  padding: 50px 0;
}
.mission-img{
  margin-bottom: 10px;
}
.mission-img img{
  width: 100%;
}
.post {
  padding: 50px 0;
}
.post .h5 {
  font-size: 18px;
  line-height: 30px;
}
.post .card-body {
  padding: 15px;
}
.post .card-text {
  font-size: 14px;
}
.contact {
  padding: 50px 0;
}
.contact h2 {
  font-size: 30px;
}
.sm-icons {
  padding: 10px;
  margin: 0 5px;
}
.heroSection h1 {
  font-size: 40px;
}
}
.container{
  overflow: hidden;
}
@media only screen and (max-width: 767px){
  .solo-img img {
    max-width: 300px;
  }
  .heroSection h1 {
    font-size: 30px;
    text-align: center;
  }
  .typetext p {
    text-align: center;
}
.heroSection .text-start{
  text-align: center !important;
}
  .hero-left-col {
    padding-bottom: 70px;
}
.about-dmm{
  flex-direction: column-reverse;
}
.about h2 {
  font-size: 20px;
  margin: 20px 0;
}
.cta .btn-cs {
  margin: 10px;
}
.post .container .row > div{
  padding: 15px;
}
.footer-desc {
  margin-bottom: 20px;
}
.footer-link .list-group .list-group-item {
  margin: 22px 0;
}
.navbar-brand img {
  width: 200px;
}
.hero {
  padding: 85px 0 0px;
}
.contact .form-static {
  padding: 15px;
}
.contact form input, .contact form textarea {
  margin: 2%;
  padding: 2%;
  width: 96%;
}
.contact form .cs-btn-form {
  width: 96%;
  margin: 2%;
  padding: 4% 5%;
}
.copyright p {
  font-size: 12px;
}
.ci-link p, .ci-link a {
  font-size: 14px !important;
}
.link li a {
  font-size: 14px !important;
}
.pl-3 {
  padding-right: 15px !important;
}
.footer-link {
  padding: 25px 0;
}
}



@media only screen and (max-width: 424px){
  .affiliate h2 {
    font-size: 20px;
    margin-bottom: 20px;
}
.heroSection h1 {
  font-size: 25px;
}
.cs-list-item h3 {
  font-size: 15px;
}
#about {
  padding: 25px 0 0;
}
.bg-gray {
  padding: 50px 0 25px;
}
.cta {
  padding: 25px 0;
}
.mission {
  padding: 25px 0;
}
.mission h3.same-h {
  min-height: 40px;
  font-size: 16px;
}
.mission h2 {
  font-size: 18px;
}
.m-row-2 {
  margin-top: 20px !important;
}
.cta h2 {
  font-size: 20px;
}
.cta .btn-cs {
  width: 100%;
  padding: 10px !important;
  margin: 10px 0 !important;
}
.cta-content {
  margin: 0;
}
.post {
  padding: 5px 0;
}
.contact {
  padding: 25px 0;
}
}